
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Organizaciones extends Vue {
  private nombreWeb: any = "";

  private documentos = [
    {
      nombre: "Comunicado de Elección Nº 15",
      link:
        "url_media/media/documentos/2021/08/certificado-15-pj.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 16",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccion-n-16.pdf"
    },
    {
      nombre: "Certificado Provisorio Nº 16 Agrupación Creando Sueños",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-16-agrup-creando-suenos.pdf"
    },
    {
      nombre:
        "Certificado Provisorio Nº 17 Comité de Adelanto Vecinal Centro Histórico de Maipú",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-17-c-adelanto-vecinal-centro-historico-de-maipu.pdf"
    },
    {
      nombre:
        "Certificado Provisorio Nº 18 Comité de Adelanto Comunidad Edificio Tristán Valdés",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-18-c-adelanto-comunidad-edificio-tristan-valdes.pdf"
    },
    {
      nombre:
        "Certificado Provisorio Nº 19 Club Social, Cultural, Deportivo Juan de la Cruz Cerda",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-19-club-soc-cult-deportivo-juan-de-la-cruz-cerda.pdf"
    },
    {
      nombre: "Certificado Provisorio Nº 20 Comité de Vivienda MiguelZaror",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccion-n-20.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 21",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccio-n-n-21.pdf"
    },
    {
      nombre:
        "Comunicado de Elección Nº 21 Comité de Adelanto y Mejoramiento La Glorieta de Maipú",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccio-n-n-21-1.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 22",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccio-n-n-22.pdf"
    },
    {
      nombre: "Ley 21.146",
      link:
        "url_media/media/documentos/2021/08/ley-21-146-1.xlsx"
    }
  ];
  private mounted() {
    document.title = "Ley 21.146 - Municipalidad de Maipú";
  }

  private toLink(item: any) {
    if (item && item != "") {
      window.open(item, "_blank");
    } else {
      return;
    }
  }
}
